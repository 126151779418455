<template>
  <div class="login-container">
    <div class="row center-login">
      <div style="width: 460px;">
        <!-- Errors container -->
        <div class="errors-container">
          <alert :type="alertClass" v-model="showError" dismissable>
            <div v-html="errorMessage" />
          </alert>
        </div>
        <div class="alert alert-success" v-if="passwordChangeMessage!=''">
          <button type="button" class="close" data-dismiss="alert">
            <span aria-hidden="true">&times;</span>
            <span class="sr-only">Close</span>
          </button>
          {{passwordChangeMessage}}
        </div>
        <div class="alert alert-danger" v-if="invalidLinkMessage!=''">
          <button type="button" class="close" data-dismiss="alert">
            <span aria-hidden="true">&times;</span>
            <span class="sr-only">Close</span>
          </button>
          {{invalidLinkMessage}}
        </div>
        <form id="loginForm" class="login-form fade-in-effect in" role="form">
          <div class="login-header" style="min-height:55px">
            <a href="javascript:void(0)" class="logo">
              <img :src="portalHeaderLogo" alt="DeltaX" style="max-height:50px; max-width: 405px;" />
            </a>
          </div>
          <!--Begin Login form -->
          <div v-show="showLoginForm">
            <div class="form-group">
              <input
                id="txtUsername"
                :placeholder="usernamePlaceholder"
                :class="usernameInputStyle"
                v-model="username"
                type="text"
                @input="UsernameInputChange"
              />
            </div>
            <div class="form-group">
              <input
                id="txtPassword"
                :type="passwordFieldType"
                :placeholder="passwordPlaceHolder"
                :class="passwordInputStyle"
                v-model="password"
                @input="PasswordInputChange"
              />
              <span
                id="spnShowHide"
                :class="showHideButtonClass"
                toggle="#password-field"
                @click="ShowHidePassword"
              />
            </div>
            <div class="form-group">
              <button
                id="btnLogin"
                :disabled="btnDisable"
                type="submit"
                class="btn btn-success btn-block text-left"
                @click.prevent="Login"
              >
                <span class="pull-left">
                  <i class="fa fa-lock" />
                  Log In
                </span>
                <i :style="spinnerStyle" class="fa fa-lg fa-spinner fa-spin pull-right" />
              </button>
            </div>
            <!-- External Login Providers Panel -->
            <div
              class="col-md-12 no-padding form-group external-login-btns"
              v-if="IsGoogleLoginProviderEnabled || IsMicrosoftLoginProviderEnabled"
            >
              <div
                class="no-padding position-relative"
                :class="{'pointer-events-none': disableSigninButton}"
                v-if="IsGoogleLoginProviderEnabled"
              >
                <google-signin
                  :params="googleSignInParams"
                  :buttonConfigurations="googleButtonConfigs"
                  @success="OnGoogleSignInSuccess"
                  @error="OnGoogleSignInError"
                  :style="GoogleSignInButtonDisableStyle"
                  :buttonHeight="40.5"
                >
                </google-signin>
                <div>
                    <i
                    :style="GoogleSignInSpinnerStyle"
                    class="fa fa-lg fa-spinner fa-spin spinner"
                  />
                  </div>
              </div>
              <div
                class="no-padding position-relative"
                :class="{'pointer-events-none': disableSigninButton}"
                v-if="IsMicrosoftLoginProviderEnabled"
              >
                <div class="ms-signin-button" @click="MicrosoftLogin" :style="MicrosoftSignInButtonDisableStyle">
                  <img class="ms-logo" src="../assets/microsoft-logo.png" />Sign in with Microsoft
                </div>
                 <div>
                    <i :style="MicrosoftSignInSpinnerStyle"
                       class="fa fa-lg fa-spinner fa-spin spinner" />
                  </div>
                </div>
              </div>
            <!-- End of External Login Providers Panel  -->
            <div class="login-footer">
              <a href="Account/ForgotPassword" :style="ForgotPasswordLinkDisableStyle" >Forgot your password?</a>
            </div>
          </div>
          <!-- End Login form -->

          <!-- Begin OTP form -->
          <div v-show="showOTPForm">
            <div class="form-group">{{ OTPNote }}</div>
            <div class="form-group">
              <input
                v-model="OTPCode"
                :placeholder="OTPPlaceHolder"
                :class="OTPInputStyle"
                type="text"
                maxlength="6"
                @input="OTPInputChange"
              />
            </div>
            <div class="form-group">
              <button
                :disabled="btnDisable"
                type="submit"
                class="btn btn-success btn-block text-left"
                @click.prevent="verifyOTP"
              >
                <span class="pull-left">
                  <i class="fa fa-lock" />
                  Verify
                </span>
                <i :style="OTPSpinnerStyle" class="fa fa-lg fa-spinner fa-spin pull-right" />
              </button>
            </div>
            <div class="login-footer">
              Didn't Receive OTP?
              <a href="#" class="resend-otp-link" @click.prevent="ResendOTP">
                Resend OTP
                <div :style="ResendOTPSpinnerStyle">
                  <i class="fa fa-lg fa-spinner fa-spin" />
                </div>
              </a>
            </div>
          </div>
          <!-- End OTP form -->
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { ApiService } from "./../Services/ApiService.js";
import { alert } from "vue-strap";
import * as Msal from "msal";

export default {
  name: "Login",
  components: {
    alert
  },
  data: function () {
    return {
      username: "",
      password: "",
      hasClicked: false,
      response: null,
      btnDisable: false,
      passwordFieldType: "password",
      showHideButtonClass: "fa fa-fw fa-eye fa-eye-slash toggle-password",
      portalDomain: "",
      errorMessage: "",
      alertStyle: "display: none;",
      returnUrl: "",
      spinnerStyle: "display: none;",
      deltaXApiUrl: "",
      portalHeaderLogo: "",
      usernamePlaceholder: "Username",
      passwordPlaceHolder: "Password",
      usernameInputStyle: "form-control",
      passwordInputStyle: "form-control",
      showLoginForm: true,
      showOTPForm: false,
      OTPNote: "An OTP has been sent to your registered email",
      OTPCode: "",
      twoFactorAuthRequired: false,
      tempAccessToken: "",
      IsGoogleLoginProviderEnabled: false,
      IsMicrosoftLoginProviderEnabled: false,
      googleSignInParams: {
        client_id: deltax.clientId
      },
      OTPSpinnerStyle: "display: none;",
      OTPPlaceHolder: "OTP",
      OTPInputStyle: "form-control",
      invalidOTPCount: 0,
      maxRetryOTPCount: 3,
      deviceToken: "",
      resendOTPCount: 0,
      ResendOTPSpinnerStyle: "display:none;",
      alertClass: "danger",
      googleUsername: "",
      GoogleSignInSpinnerStyle: "display:none;",
      showGoogleSigninButtonSpinner: false,
      MicrosoftSignInSpinnerStyle: "display:none;",
      showMicrosoftSigninButtonSpinner: false,
      GoogleSignInButtonDisableStyle: "",
      MicrosoftSignInButtonDisableStyle: "",
      ForgotPasswordLinkDisableStyle: "",
      showError: false,
      passwordChangeMessage: deltax.passwordChangeSuccessMessage,
      invalidLinkMessage: deltax.invalidLinkMessage,
      microsoftSigninParams: {
        auth: {
          clientId: deltax.MicrosoftClientId,
          authority: "https://login.microsoftonline.com/common"
        }
      },
      googleButtonConfigs: {
        width: '190'
      },
      msalInstance: new Msal.UserAgentApplication({
        auth: {
          clientId: deltax.MicrosoftClientId,
          authority: "https://login.microsoftonline.com/common"
        }
      })
    };
  },
  computed: {
    requiredUserName () {
      return this.username.trim().length == 0
        ? "Please enter your username"
        : "";
    },
    requiredPassword () {
      return this.password.trim().length == 0
        ? "Please enter your password"
        : "";
    },
    disableSigninButton () {
      return (this.btnDisable || this.showGoogleSigninButtonSpinner || this.showMicrosoftSigninButtonSpinner);
    }
  },
  created: function () {
    this.portalHeaderLogo = deltax.portalHeaderLogo;
    this.portalDomain = window.location.hostname;
    this.returnUrl = deltax.returnUrl;
    this.IsGoogleLoginProviderEnabled = deltax.IsGoogleLoginEnabled == "True";
    this.IsMicrosoftLoginProviderEnabled = deltax.IsMicrosoftLoginEnabled == "True";
    this.deviceToken = deltax.deviceTokenValue;
  },
  methods: {
    ShowHidePassword: function () {
      this.passwordFieldType =
        this.passwordFieldType == "password" ? "text" : "password";
      if (this.passwordFieldType == "password") {
        this.showHideButtonClass =
          "fa fa-fw fa-eye fa-eye-slash toggle-password";
      } else {
        this.showHideButtonClass = "fa fa-fw fa-eye field-icon toggle-password";
      }
    },
    Login: function () {
      var self = this;
      if (
        self.username.trim().length == 0 &&
        self.password.trim().length == 0
      ) {
        this.usernamePlaceholder = "Please enter your username";
        this.passwordPlaceHolder = "Please enter your password";
        this.usernameInputStyle = "form-control error";
        this.passwordInputStyle = "form-control error";
        return false;
      } else if (self.username.trim().length == 0) {
        this.usernameInputStyle = "form-control error";
        this.usernamePlaceholder = "Please enter your username";
        return false;
      } else if (self.password.trim().length == 0) {
        this.passwordInputStyle = "form-control error";
        this.passwordPlaceHolder = "Please enter your password";
        return false;
      } else {
        this.usernameInputStyle = "form-control";
        this.passwordInputStyle = "form-control";
        this.spinnerStyle = "display: block;";
        this.ForgotPasswordLinkDisableStyle = "pointer-events: none;";
        this.btnDisable = true;
        // Call DeltaX.Api
        ApiService.Login(self)
          .then(response => {
            ApiService.verifyTwoFactorEnabled(self, response);
          })
          .catch(error => {
            this.errorMessage = ApiService.convertErrorRespsonse(
              error.response.data
            );
            this.alertStyle = "display: block;";
            this.showError = true;
            this.spinnerStyle = "display: none;";
            this.btnDisable = false;
            this.alertClass = "danger";
            this.ForgotPasswordLinkDisableStyle = "";
          });
      }
    },
    closeAlert: function () {
      this.errorMessage = "";
    },
    OnGoogleSignInSuccess: function (googleUser) {
      var self = this;
      this.btnDisable = true;
      this.GoogleSignInSpinnerStyle = "color: #000; font-size: 20px;";
      this.showGoogleSigninButtonSpinner = true;
      this.GoogleSignInButtonDisableStyle = "opacity: 0.5;";
      this.ForgotPasswordLinkDisableStyle = "pointer-events: none;";
      self.token = googleUser.credential;
      ApiService.LoginWithGoogle(self)
        .then(response => {
          ApiService.verifyTwoFactorEnabled(self, response);
        })
        .catch(error => {
          if (error.response && error.response.data) {
            this.errorMessage = ApiService.convertErrorRespsonse(
              error.response.data, "Google"
            );
          } else {
            this.errorMessage =
              "Authentication through Google failed. Please try again, and if the issue continues, contact support for assistance.";
          }
          this.alertStyle = "display: block;";
          this.showError = true;
          this.spinnerStyle = "display: none;";
          this.btnDisable = false;
          this.alertClass = "danger";
          this.GoogleSignInSpinnerStyle = "display: none;";
          this.showGoogleSigninButtonSpinner = false;
          this.GoogleSignInButtonDisableStyle = "";
          this.ForgotPasswordLinkDisableStyle = "";
        });
    },
    OnGoogleSignInError: function () {
      this.errorMessage =
        "Authentication through Google failed. Please try again, and if the issue continues, contact support for assistance.";
      this.btnDisable = false;
      this.GoogleSignInSpinnerStyle = "display: none;";
      this.showGoogleSigninButtonSpinner = false;
      this.GoogleSignInButtonDisableStyle = "";
      this.ForgotPasswordLinkDisableStyle = "";
    },
    UsernameInputChange () {
      if (this.username.trim().length > 0) {
        this.usernameInputStyle = "form-control";
      } else {
        this.usernameInputStyle = "form-control error";
      }
    },
    PasswordInputChange () {
      if (this.password.trim().length > 0) {
        this.passwordInputStyle = "form-control";
      } else {
        this.passwordInputStyle = "form-control error";
      }
    },
    // OTP related
    verifyOTP: function () {
      var self = this;
      this.OTPSpinnerStyle = "display: block;";

      if (self.OTPCode.trim().length == 0) {
        this.OTPPlaceHolder = "Please enter OTP";
        this.OTPInputStyle = "form-control error";
        this.OTPSpinnerStyle = "display: none;";
        return false;
      } else {
        this.btnDisable = true;
        ApiService.validateOTP(self.tempAccessToken, self.OTPCode)
          .then(response => {
            ApiService.createSessionAndRedirect(this.returnUrl, response);
          })
          .catch(error => {
            this.alertStyle = "display: block;";
            this.showError = true;
            this.alertClass = "danger";
            this.OTPSpinnerStyle = "display: none;";
            this.invalidOTPCount = this.invalidOTPCount + 1;
            var attemptsLeft = this.maxRetryOTPCount - this.invalidOTPCount;
            var InvalidOTPMessage = ApiService.convertErrorRespsonse(
              error.response.data
            );
            this.errorMessage =
              InvalidOTPMessage +
              ".<br>You have <b>" +
              attemptsLeft +
              "</b> attempt(s) left.";
            this.btnDisable = false;
            if (this.invalidOTPCount == this.maxRetryOTPCount) {
              this.errorMessage = "";
              this.showLoginForm = true;
              this.showOTPForm = false;
              this.spinnerStyle = "display: none;";
              this.btnDisable = false;
            }
          });
      }
    },
    OTPInputChange () {
      if (this.OTPCode.trim().length > 0) {
        this.OTPInputStyle = "form-control";
      } else {
        this.OTPInputStyle = "form-control error";
      }
    },
    ResendOTP: function () {
      var self = this;
      this.ResendOTPSpinnerStyle = "display: inline-block;";
      if (this.resendOTPCount == this.maxRetryOTPCount) {
        this.showLoginForm = true;
        this.showOTPForm = false;
        this.errorMessage = "Please try again after sometime.";
        this.btnDisable = false;
        this.spinnerStyle = "display: none;";
        this.ResendOTPSpinnerStyle = "display:none;";
        this.OTPSpinnerStyle = "display: none;";
        this.alertStyle = "display: block;";
        this.showError = true;
        this.alertClass = "danger";
      } else {
        this.btnDisable = true;
        ApiService.ResendOtp(self.tempAccessToken)
          .then(response => {
            this.errorMessage = ApiService.getResendOTPConfirmationNote(
              response.data
            );
            this.ResendOTPSpinnerStyle = "display:none;";
            this.resendOTPCount = this.resendOTPCount + 1;
            this.alertStyle = "display: block;";
            this.showError = true;
            this.btnDisable = false;
            this.alertClass = "success";
          })
          .catch(error => {
            console.log(error);
            this.errorMessage =
              "Something went wrong.Please contact Administrator.";
            this.ResendOTPSpinnerStyle = "display:none;";
            this.resendOTPCount = this.resendOTPCount + 1;
            this.alertStyle = "display: block;";
            this.showError = true;
            this.btnDisable = false;
            this.alertClass = "danger";
          });
      }
    },
    OnMicrosoftSigninSuccess: function (response) {
      var self = this;
      this.btnDisable = true;
      this.MicrosoftSignInSpinnerStyle = "color: #000; font-size: 20px;"
      this.showMicrosoftSigninButtonSpinner = true;
      this.MicrosoftSignInButtonDisableStyle = "opacity: 0.5;";
      self.token = response.idToken;
      ApiService.LoginWithMicrosoft(self)
        .then(response => {
          ApiService.verifyTwoFactorEnabled(self, response);
        })
        .catch(error => {
          if (error.response && error.response.data) {
            this.errorMessage = ApiService.convertErrorRespsonse(
              error.response.data, "Microsoft"
            );
          } else {
            this.errorMessage =
              "Authentication through Microsoft failed. Please try again, and if the issue continues, contact support for assistance.";
          }
          this.alertStyle = "display: block;";
          this.showError = true;
          this.spinnerStyle = "display: none;";
          this.btnDisable = false;
          this.alertClass = "danger";
          this.MicrosoftSignInSpinnerStyle = "display: none;";
          this.showMicrosoftSigninButtonSpinner = false;
          this.MicrosoftSignInButtonDisableStyle = "";
        });
    },
    OnMicrosoftSigninError: function () {
      this.errorMessage =
        "Authentication through Microsoft failed. Please try again, and if the issue continues, contact support for assistance.";
      this.alertStyle = "display: block;";
      this.showError = false;
      this.spinnerStyle = "display: none;";
      this.btnDisable = false;
      this.alertClass = "danger";
      this.MicrosoftSignInSpinnerStyle = "display: none;";
      this.showMicrosoftSigninButtonSpinner = false;
      this.MicrosoftSignInButtonDisableStyle = "";
    },
    MicrosoftLogin: function () {
      var self = this;
      this.btnDisable = true;
      this.MicrosoftSignInSpinnerStyle = "color: #000; font-size: 20px;"
      this.showMicrosoftSigninButtonSpinner = true;
      this.MicrosoftSignInButtonDisableStyle = "opacity: 0.5;";
      self.msalInstance.handleRedirectCallback((error, response) => {
        if (error) {
          console.log(error);
        } else {
          if (response.tokenType === "access_token") {
          } else {
            console.log("token type is:" + response.tokenType);
          }
        }
      });

      var loginRequest = {
        scopes: ["user.read"] // optional Array<string>
      };

      self.msalInstance
        .loginPopup(loginRequest)
        .then(response => {
          self.username = response.account.userName;
          ApiService.LoginWithMicrosoft(self)
            .then(response => {
              ApiService.verifyTwoFactorEnabled(self, response);
            })
            .catch(error => {
              this.errorMessage = ApiService.convertErrorRespsonse(
                error.response.data
              );
              this.alertStyle = "display: block;";
              this.showError = true;
              this.spinnerStyle = "display: none;";
              this.btnDisable = false;
              this.alertClass = "danger";
              this.MicrosoftSignInSpinnerStyle = "display: none;";
              this.showMicrosoftSigninButtonSpinner = false;
              this.MicrosoftSignInButtonDisableStyle = "";
            });
        })
        .catch(error => {
          this.alertStyle = "display: block;";
          this.showError = false;
          this.spinnerStyle = "display: none;";
          this.btnDisable = false;
          this.alertClass = "danger";
          this.MicrosoftSignInSpinnerStyle = "display: none;";
          this.showMicrosoftSigninButtonSpinner = false;
          this.MicrosoftSignInButtonDisableStyle = "";
          console.log(error);
        });
    }
  }
};
</script>
<style>
.field-icon,
.fa-eye-slash {
  font-size: 1.33em;
  margin-right: 10px;
  float: right;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
.external-providers {
  font-weight: bold;
}
.resend-otp-link {
  color: blue !important;
  text-decoration: underline;
}

/* Removes weird vue transition added by vue-strap */
.fade-enter-active,
.fade-leave-active {
  transition: none !important;
}

/* Move the login box a bit higher to accomodate "Sign in with Google" */
.login-page {
  padding-top: 100px !important;
}
.lock-padding {
  padding-right: 5px;
}
.span-padding {
  padding-left: 5px;
}
.login-padding {
  padding-bottom: 10px;
}
.button-spacing{
  padding-left:10px !important;
}
.float-left{
  margin-left: -35px;
}
.spinner {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.external-login-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.position-relative {
  position: relative;
}
.pointer-events-none {
  pointer-events: none;
}
.ms-signin-button {
  font-family: 'Segoe UI';
  font-weight: 500;
  font-size: 14px;
  max-width: 400px;
  max-height: 60px;
  height: 40px;
  padding: 8px 12px;
  border-radius: 4px;
  letter-spacing: 0.25px;
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  cursor: pointer;
  color: #3c4043;
  border: 1px solid #dadce0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.ms-logo {
  margin-right: 12px;
}
</style>
